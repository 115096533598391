import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useCookies, withCookies } from "react-cookie";
import ReactGA from "react-ga4";
import { useRecoilState, useSetRecoilState } from "recoil";
import "./App.scss";
import { initGA } from "./utils/tracking";
import Pageroutes from "./routers/routers";
import WrapLoading from "./components/common/loading/WrapLoading";
import {
  Service_CN,
  Service_GL,
  checkPosition,
  naverAuthorizePopUp,
  redirectURL
} from "./utils/CommonFunc";
import { useQuery } from "react-query";
import axios from "axios";
import {
  connectSiteState,
  isLauncherState,
  isLogInState,
  isPrivacyAgreeState,
  naverAuthorizeUrl,
  ssoinfoState,
  userBirthState,
  userIdState,
  userNicknameState,
  userProfileImgState,
  userProfileKeyState,
  userSerialNumState
} from "./recoil/userInfoAtom";
import {
  GET_MEMBERINFO,
  ILoginData,
  IMember,
  checkWebTokenApi
} from "./api/userInfoApi";
import { ILang, getLanguage } from "./api/commonApi";
import i18n from "./language/i18n";
import { langCodeState, langListState, langState } from "./recoil/commonUiAtom";
import ResetInfo from "./utils/ResetInfo";

function App() {
  const setState = {
    setIsLogIn: useSetRecoilState(isLogInState),
    setUserID: useSetRecoilState(userIdState),
    setSSOInfo: useSetRecoilState(ssoinfoState),
    setUserBirth: useSetRecoilState(userBirthState),
    setConnectSite: useSetRecoilState(connectSiteState),
    setIsPrivacyAgree: useSetRecoilState(isPrivacyAgreeState),
    setNaverAuthorize: useSetRecoilState(naverAuthorizeUrl),
    setUserNickName: useSetRecoilState(userNicknameState),
    setUserSerial: useSetRecoilState(userSerialNumState),
    setLang: useSetRecoilState(langState),
    setUserProfileKey: useSetRecoilState(userProfileKeyState),
    setUserProfileImg: useSetRecoilState(userProfileImgState)
  };

  const [langCode, setLangCode] = useRecoilState(langCodeState);
  const [cookies, setCookie, removeCookie] = useCookies();
  const [langList, setLangList] = useRecoilState(langListState);
  const launcher = window.navigator.userAgent.split("::")[1];
  const [isLauncher, setIsLauncher] = useRecoilState(isLauncherState);
  const service_code = window.location.href.split("/")[4];

  // 언어
  const { isLoading: langLoading, data: langData } = useQuery<ILang>(
    "lang",
    getLanguage
  );

  // 런처 여부
  useEffect(() => {
    if (launcher === "vfun-launcher") {
      setIsLauncher(true);
    }
  }, [launcher]);

  // 런처 로그인, 아웃
  useEffect(() => {
    window.vfunLogin = async function (webToken: string) {
      try {
        let res = await axios.post(checkWebTokenApi, {
          web_token: webToken
        });
        const data: ILoginData = res.data;
        if (res.data.result === 1) {
          setCookie("birthday", data.data.birthday, {
            path: "/",
            domain: process.env.REACT_APP_COOKIE_DOMAIN
          });
          setCookie("sso_info_new", data.data.sso_info_new, {
            path: "/",
            domain: process.env.REACT_APP_COOKIE_DOMAIN
          });
          setCookie("sso_info", data.data.sso_info, {
            path: "/",
            domain: process.env.REACT_APP_COOKIE_DOMAIN
          });
          setCookie("userid", data.data.user_id, {
            path: "/",
            domain: process.env.REACT_APP_COOKIE_DOMAIN
          });
          redirectURL(window.location.href, "_self", isLauncher);
        } else {
          alert("잠시후 다시 시도해주세요");
        }
      } catch (error) {
        console.log("launcher", error);
      }
    };
    window.vfunLogout = function () {
      Object.keys(cookies).forEach((keys) => {
        if (cookies["rememberId"] === "Y") {
          if (keys !== "userid" && keys !== "rememberId") {
            removeCookie(keys, {
              path: "/",
              domain: process.env.REACT_APP_COOKIE_DOMAIN
            });
          }
        } else {
          removeCookie(keys, {
            path: "/",
            domain: process.env.REACT_APP_COOKIE_DOMAIN
          });
        }

        window.localStorage.removeItem("prevPath");
        redirectURL(window.location.href, "_self", isLauncher);
      });
    };
    window.vfunLangChange = function (lang: string, lang_code: string) {
      setCookie("locale", lang, {
        path: "/",
        domain: process.env.REACT_APP_COOKIE_DOMAIN
      });
      i18n.changeLanguage(lang);
      setState.setLang(lang_code);
      setLangCode(lang);
    };
  }, []);

  useEffect(() => {
    if (langData?.result === 1) {
      setLangList(langData.data);
    }
  }, [langLoading]);

  // 언어 쿠키 참조
  useEffect(() => {
    if (Service_GL) {
      if (Service_CN) {
        setCookie("locale", "cn", {
          path: "/",
          domain: process.env.REACT_APP_COOKIE_DOMAIN
        });
        setState.setLang("chinese");
        setLangCode("cn");
        i18n.changeLanguage("cn");
      }
      // 글로벌 일 경우
      if (cookies["locale"] !== undefined || cookies["locale"] !== null) {
        for (let i = 0; i < langList.length; i++) {
          if (langList[i].lang === cookies["locale"]) {
            setCookie("locale", cookies["locale"], {
              path: "/",
              domain: ".valofe.com"
            });
            setState.setLang(langList[i].lang_code);
            setLangCode(cookies["locale"]);
            i18n.changeLanguage(cookies["locale"]);
          }
        }
      } else {
        setCookie("locale", "en", { path: "/", domain: ".valofe.com" });
        setState.setLang("English");
        setLangCode("en");
        i18n.changeLanguage("en");
      }
    } else {
      setCookie("locale", "ko", { path: "/", domain: ".valofe.com" });
      setState.setLang("한국어");
      setLangCode("ko");
      i18n.changeLanguage("ko");
    }
  }, [langList]);

  const [isLoading, setIsLoading] = useState(true);

  // 쿠키 및
  useEffect(() => {
    if (
      window.location.hostname.includes(".valofe.com") ||
      window.location.hostname.includes("localhost")
    ) {
      valofe_process();
      setState.setConnectSite("vfun");

      if (!Service_GL) {
        initGA("G-5V54JQQ53S");
        ReactGA.send("pageview");
        ReactGA.event({
          category: "User",
          action: "Created an Account",
          label: "VFUN KR 회원 가입"
        });
      }
    } else if (window.location.hostname.includes(".xiyouxi.com")) {
      xiyouxi_process();
      setState.setConnectSite("vfun");
      //2023. 7월 1일부터 google GA가 UA 속성 지원하지 않음
      initGA("G-SSZTQZCZJF");
      ReactGA.send("pageview"); // 페이지 뷰가 다른 이벤트 추척보다 선행되어야 함
      ReactGA.event({
        category: "User",
        action: "Created an Account", //계정생성 추적
        label: "加入喜游戏 Lounge 会员" // + 서비스 코드가 들어가면 더 좋겠습니다.
      });
      setCookie("service_code", "lounge", {
        path: "/",
        domain: process.env.REACT_APP_COOKIE_DOMAIN
      });
    } else {
      setState.setConnectSite("naver");
      naver_process();
      window.addEventListener(
        "message",
        (e) => e.data.message === "naver_agree" && window.location.reload()
      );
    }
  }, []);

  const memberInfoProcess = async (
    Ssoinfo: string,
    Userid: string,
    Userbirth: string,
    Channelingtype: string
  ) => {
    try {
      let res: any = await GET_MEMBERINFO(
        {
          Ssoinfo: encodeURIComponent(Ssoinfo),
          Userbirth: Userbirth,
          Userid: Userid,
          Channelingtype: Channelingtype
        },
        window.location.pathname.split("/")[1] === "space"
          ? cookies["nv_service_code"]
          : service_code
      );

      if (res.result === 1) {
        const userData: IMember = res;

        setState.setIsLogIn(true);
        setState.setUserID(userData.data.user_id);
        if (!window.location.hostname.includes("game.naver.com")) {
          setState.setSSOInfo(userData.sso_info);
        }
        setState.setUserNickName(userData.data.nickName || "");
        setState.setUserSerial(Number(userData.data.user_Serial));
        setState.setUserProfileImg(userData.data.profile_image_url || "");
        setState.setUserProfileKey(userData.data.user_profile_key);
        setState.setIsPrivacyAgree(true);

        if (Service_GL) {
          setState.setUserBirth(userData.data.user_birthday);
        }
      } else if (res.result === -82) {
        checkPosition();
        naverAuthorizePopUp(res.data.authorize);
        setState.setIsPrivacyAgree(false);
        setState.setNaverAuthorize(res.data.authorize);
      } else if (res.result === -81) {
        alert("네이버 인증 정보가 없습니다. 고객센터로 문의해주세요.");
      } else {
        removeCookie("sso_info_new", {
          path: "/",
          domain: process.env.REACT_APP_COOKIE_DOMAIN
        });
        removeCookie("userid", {
          path: "/",
          domain: process.env.REACT_APP_COOKIE_DOMAIN
        });
        removeCookie("birthday", {
          path: "/",
          domain: process.env.REACT_APP_COOKIE_DOMAIN
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const valofe_process = async () => {
    if (Service_GL) {
      // 글로벌
      setState.setIsPrivacyAgree(true);
      let Ssoinfo = cookies["sso_info_new"];
      let Userid = cookies["userid"];
      let Userbirth = cookies["birthday"];

      if (Ssoinfo !== undefined) {
        await memberInfoProcess(Ssoinfo, Userid, Userbirth, "vfun");
      }
      setIsLoading(false);
    } else {
      // 한국
      setState.setIsPrivacyAgree(true);
      let cookies_goonzu = cookies["goonzu"];

      if (cookies_goonzu === undefined) {
        setState.setIsLogIn(false);
      } else {
        let cookies_info = cookies_goonzu.split("&");
        if (cookies_info.length > 0) {
          let param: any = [];
          let data = () => {
            for (let i = 0; i < cookies_info.length; i++) {
              let key = cookies_info[i].split("=")[0];
              let value = cookies_info[i].split("=")[1];
              param[key] = value;
            }

            return param;
          };

          let Ssoinfo =
            cookies[process.env.REACT_APP_SSO_INFO_COOKIE_NAME || ""];
          let Userbirth = cookies["jumin"];
          let Userid = decodeURI(data().userid);

          await memberInfoProcess(Ssoinfo, Userid, Userbirth, "vfun");

          setState.setUserBirth(Userbirth);
        }
      }
      setIsLoading(false);
    }
  };

  const xiyouxi_process = async () => {
    // cn
    setState.setIsPrivacyAgree(true);
    let cookies_ssoinfo = encodeURIComponent(cookies["authorization"]);
    let cookies_userid = cookies["userid"];
    let cookies_birthday = cookies["birthday"];
    let LStorage_ssoinfo =
      localStorage.getItem("ssoinfo") === undefined
        ? ""
        : localStorage.getItem("ssoinfo");

    if (cookies_ssoinfo !== undefined) {
      if (LStorage_ssoinfo === undefined || LStorage_ssoinfo === null) {
        try {
          let res: any = await GET_MEMBERINFO(
            {
              Ssoinfo: cookies_ssoinfo,
              Userid: cookies_userid,
              Userbirth: cookies_birthday
            },
            ""
          );
          if (res.result === 1) {
            setState.setIsLogIn(true);
            setState.setUserID(res.data.user_id);
            setState.setSSOInfo(res.sso_info);
            setState.setUserBirth(res.data.user_birthday);
          }
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
        }
      }
    }
  };

  const naver_process = async () => {
    const gdp_login = cookies["GDP_LOGIN"];

    if (gdp_login === undefined) {
      setState.setIsLogIn(false);
      setState.setIsPrivacyAgree(false);
    } else {
      await memberInfoProcess(gdp_login, "none", "none", "naver");
      setState.setIsLogIn(true);
      setState.setSSOInfo(gdp_login);
      setState.setUserBirth("none");
    }
    setIsLoading(false);
  };

  return (
    <Router>
      {isLoading ? (
        <WrapLoading />
      ) : (
        <>
          <ResetInfo />
          <Pageroutes />
        </>
      )}
    </Router>
  );
}

export default withCookies(App);
