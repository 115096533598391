import React from "react";
import PageHeader from "components/community/pageHeader/PageHeader";
import "language/i18n";
import Wrapper from "styles/Wrapper";
import PageLeft from "components/community/pageLeft/PageLeft";
import "./GameLounge.scss";
import PageRight from "components/community/pageRight/PageRight";
import { connectSiteState } from "recoil/userInfoAtom";
import { useRecoilValue } from "recoil";

function GameLounge() {
  const connectSite = useRecoilValue(connectSiteState);

  return (
    <Wrapper title="" isFooter={true}>
      <PageHeader />
      <section className="page">
        <div className="page-body">
          <div className="row-w clearfix">
            <PageLeft />
            <PageRight />
          </div>
        </div>
      </section>
    </Wrapper>
  );
}

export default GameLounge;
