import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "./LikeablePosts.scoped.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faN } from "@fortawesome/free-solid-svg-icons";
import { useQuery } from "react-query";
import { ILikelounge, getLikeList } from "api/postListApi";
import { useRecoilState, useRecoilValue } from "recoil";
import { langCodeState } from "recoil/commonUiAtom";
import { Service_GL, dateFormType1, redirectURL } from "utils/CommonFunc";
import FourListLoading from "components/common/loading/FourListLoading";
import { deletetag } from "utils/DeleteTag";
import { useInView } from "react-intersection-observer";
import NotContent from "components/community/pageRight/PostContent/NotContent";
import ScrollLoading from "components/community/pageRight/PostContent/ScrollLoading";
import { useInfiniteScrollQuery } from "api/profileLikePostApi";
import { isLauncherState } from "recoil/userInfoAtom";
import { useCookiesServiceCode } from "hooks/useCookiesServiceCode";
import { t } from "i18next";
import { IMAGE_DNS } from "utils/config/Config";
import {
  spacePageTitleState,
  spaceSubCategoryState
} from "recoil/contentsAtom";

const tabList = [
  // { name: "my posts", class: "my-posts-tab" },
  // { name: "my comments", class: "my-comments-tab" },
  { name: "posts", class: "post-tab" },
  { name: "game", class: "lounge-tab" }
];

interface IPosts {
  content: string;
  created_at: string;
  image: string[] | null;
  title: string;
  writer: string;
  writer_thumbnail: string | null;
  url: string;
}

function LikeablePosts() {
  const { profilekey } = useParams();
  const langCode = useRecoilValue(langCodeState);
  const [spaceSubCategory, setSpaceSubCategory] = useRecoilState(
    spaceSubCategoryState
  );
  const isLauncher = useRecoilValue(isLauncherState);
  const scCookies = useCookiesServiceCode();
  const spacePageTitle = useRecoilValue(spacePageTitleState);

  const { isLoading: loungeLoading, data: loungeData } = useQuery<ILikelounge>(
    ["like-lounge"],
    () => getLikeList(profilekey || "", langCode, "lounge", scCookies),
    {
      enabled: spaceSubCategory === "lounge-tab"
    }
  );

  const { getBoard, getNextPage, getBoardIsSuccess, getNextPageIsPossible } =
    useInfiniteScrollQuery("posts");

  const [ref, isView] = useInView();

  useEffect(() => {
    if (isView && getNextPageIsPossible) {
      getNextPage();
    }
  }, [isView, getBoard]);

  useEffect(() => {
    if (spacePageTitle === "like") {
      const validTabClasses = tabList.map((tab) => tab.class);
      if (!validTabClasses.includes(spaceSubCategory)) {
        setSpaceSubCategory("post-tab");
      }
    }
  }, [spacePageTitle]);

  const clickSection = (selecMenu: string) => {
    setSpaceSubCategory(selecMenu);
  };

  return (
    <div className="page-right" id="LikeablePosts">
      <ul className="tab">
        {tabList.map((i, idx) => (
          <li
            onClick={() => clickSection(i.class)}
            key={idx}
            className={i.class === spaceSubCategory ? "on" : ""}
          >
            <Link to="#">{t(i.name)}</Link>
          </li>
        ))}
      </ul>

      <div className="cont">
        {spaceSubCategory === "lounge-tab" ? (
          !loungeLoading ? (
            <ul>
              {loungeData?.result === 1 ? (
                loungeData.data.map((i, idx: number) => (
                  <li key={idx}>
                    <img src={i.thumbnail} />
                    <div>
                      <div>
                        <p>{i.title}</p>
                        {i.is_new && (
                          <span>
                            <FontAwesomeIcon icon={faN} />
                          </span>
                        )}
                      </div>
                      <div className="btns">
                        <button
                          onClick={() =>
                            redirectURL(i.lounge_url, "_self", isLauncher)
                          }
                        >
                          Lounge
                          <FontAwesomeIcon icon={faAngleRight} />
                        </button>
                        <button
                          onClick={() =>
                            redirectURL(i.library_url, "_self", isLauncher)
                          }
                        >
                          {Service_GL ? "Library" : "홈페이지"}
                          <FontAwesomeIcon icon={faAngleRight} />
                        </button>
                      </div>
                    </div>
                  </li>
                ))
              ) : (
                <div className="article img _0 clearfix">
                  <div className="cont no-article clearfix">
                    <img
                      className=""
                      src={
                        IMAGE_DNS +
                        "/Valofe_file/web/vfun-ko/images/community/page/funny-no-contents-min.png"
                      }
                      alt={t("There_are_no_game")}
                    />
                    <p>{t("follow_the_games_that_you_like_to_play")}</p>
                  </div>
                </div>
              )}
            </ul>
          ) : (
            <FourListLoading />
          )
        ) : spaceSubCategory === "post-tab" ? (
          <div>
            {getBoardIsSuccess ? (
              getBoard?.pages[0].result === 1 ? (
                getBoard?.pages.map((pageData) =>
                  pageData.boardpage.map((i: IPosts, idx: number) => (
                    <div
                      className="box"
                      key={idx}
                      onClick={() => redirectURL(i.url, "_self", isLauncher)}
                    >
                      <div>
                        <div className="title">{deletetag(i.title, [])}</div>
                        <p className="ellipsis-multi">
                          {deletetag(i.content, [])}
                        </p>
                        <div className="info">
                          <span>
                            <img
                              className="user-pic"
                              src={
                                i.writer_thumbnail !== null
                                  ? i.writer_thumbnail
                                  : "https://file.valofe.com/Valofe_file/web/vfun-ko/images/common/img-user-default-min.png"
                              }
                              alt="user icon"
                            />
                          </span>
                          <span className="nickname">{i.writer}</span>
                          <span className="time">
                            {dateFormType1(i.created_at)}
                          </span>
                        </div>
                      </div>
                      <div className="img">
                        {i.image !== null && <img src={i.image[0]} />}
                      </div>
                    </div>
                  ))
                )
              ) : (
                <NotContent />
              )
            ) : null}

            <ScrollLoading
              loadingConditions={
                getNextPageIsPossible === true &&
                getBoard?.pages[0].result === 1
              }
              viewRef={ref}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default LikeablePosts;
